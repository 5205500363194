@import '../../fonts/fonts';

.Dashboard {
    &__logo {
        padding: 6px;
        display: flex;
        justify-content: center;
        background-color: #1a1d28;
    }
    &__appBar {
        .MuiToolbar-gutters {
            background-color: #1a1d28;
            min-height: 63px;
        }
    }
    &__drawer {
        .MuiPaper-elevation {
            background-color: #1a1d28;
            color: #ffffff;
            min-width: auto;
            svg {
                width: 25px;
                height: 25px;
                fill: #ffffff;
            }
        }
    }
    &__list {
        display: flex;
        flex-direction: column;
        align-items: center;
        &-item {
            &:first-child {
                margin-top: 20px;
                background-color: #002c6d ;
            }
            display: none;
            background-color: #3b5278;
            border-radius: 10px;
            margin-bottom: 20px;
            width: 90% !important;
            .MuiTypography-body1 {
                font-size: 1rem;
            }
        }
    }
}