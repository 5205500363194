.IframeCreatives {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 40px 10px;
    width: 100%;
    height: 100%;
    margin-bottom: 160px;
    .card-iframe.darkmode{
        background-color: #FFF;
        .bannertext{
            color: #000;
        }
    }
}