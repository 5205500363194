@import '../../fonts/fonts';

.card-iframe {
    // margin: 20px;
    // background-color: white;
    // padding: 20px 20px 20px 20px;
    // border-radius: 10px;
    // position: relative;
        // background-color: #fff;
        // border-radius: 0 5px 5px;
        // margin: 20px;
        // padding: 20px;
        // position: relative;
        // box-shadow: 0 0 11px 0px rgb(71 71 71 / 22%);
        background-color: #fff;
        // border-radius: 0 0 5px 5px;
        border-radius: 0 0 8px 8px;
        box-shadow: 0 0 11px 0 rgba(71,71,71,.22);
        margin: 20px;
        padding: 20px 20px 20px 20px;
        position: relative;
        transition: all ease-in-out .7s;
  
    iframe {
        border: none;
        overflow: hidden;
    }
    
    .bannertext{
        display: block;
        font-size: 14px;
        padding-bottom: 10px;
        font-family: $font-poppins;
    }

    &__info {
        // position: absolute;
        // top: -24px;
        // left: 20px;
        // margin: 0 auto;
        // display: flex;
        // justify-content: space-between;
        // width: calc(100% - 25px);
        display: flex;
        justify-content: space-between;
        left: 0px;
        margin: 0 auto;
        position: absolute;
        top: -28px;
        width: calc(100% - 0px);
        &-reload {
            // cursor: pointer;
            // background-color: #0080ff;
            // border-radius: 50%;
            // display: flex;
            // padding: 4px;
            background-color: #0080ff;
            border-radius: 50% 50% 0 0;
            cursor: pointer;
            display: flex;
            padding: 4px;
            img {
                width: 19px;

            }
        }
        &-size {

            align-items: center;
            background-color: #0080ff;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            color: #fff;
            display: flex;
            font-family: $font-poppins;
            font-size: 11px;
            font-weight: 600;
            font-weight: 300;
            justify-content: center;
            line-height: 1;
            padding: 0 18px;
            // font-family: $font-poppins;
            // font-weight: 600;
            // background-color: #0080ff;
            // color: #fff;
            // padding: 0 18px;
            // line-height: 1;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // border-top-left-radius: 10px;
            // border-top-right-radius: 10px;
            // font-weight: 300;
            // font-size: 14px;
        }
    }
    &.darkmode {
        margin-bottom: 20px !important;
        background-color: #272D3D;
        .bannertext{
            color: #FFF;
        }
    }

}