@font-face {
    font-family: 'Gotham-Black';
    src: url(./Gotham-Black.woff) format('woff'),
         url(./Gotham-Black.ttf) format('truetype'),
         url(./Gotham-Black.svg) format('svg');
    font-weight: normal;
}
@font-face {
    font-family: 'Gotham-Bold';
    src: url(./Gotham-Bold.woff) format('woff'),
         url(./Gotham-Bold.ttf) format('truetype'),
         url(./Gotham-Bold.svg) format('svg');
    font-weight: normal;
}
@font-face {
    font-family: 'Gotham-Book';
    src: url(./Gotham-Book.woff) format('woff'),
         url(./Gotham-Book.woff2) format('woff2'),
         url(./Gotham-Book.ttf) format('truetype'),
         url(./Gotham-Book.svg) format('svg');
    font-weight: normal;
}
@font-face {
    font-family: 'Gotham-Light';
    src: url(./Gotham-Light.woff) format('woff'),
         url(./Gotham-Light.ttf) format('truetype'),
         url(./Gotham-Light.svg) format('svg');
    font-weight: normal;
}

$font-book       : 'Gotham-Book';
$font-light      : 'Gotham-Light';
$font-bold       : 'Gotham-Bold';
$font-black      : 'Gotham-Black';
$font-poppins: 'Poppins', 'sans-serif';