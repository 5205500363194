@import '../../fonts/fonts';

.modal-filter{
    &__form{
        .container{
            display: flex;
        }

        label{
            font-family: $font-poppins;
            display: block;
            font-weight: 600;
            text-transform: uppercase;
            color: #0a2756;
            margin-bottom: 8px;
        }
        .field{
            margin: 0 8px;
            .dates{
                display: flex;
                .MuiInputBase-root{
                    flex-direction: row-reverse;
                    background: #f2f2f2;
                    padding-right: 0;
                    margin: 0 10px 0 0;
                }
                .MuiButtonBase-root{
                    padding: 0 5px 0 0;
                    svg{
                        fill: #0a2756;
                    }
                }
            }
            fieldset{
                border: none;
            }
        }
        input , select{
            padding: 8px;
            border-radius: 5px;
            border: none;
            font-family: $font-poppins;
            font-weight: 500;
            background: #f2f2f2;
            color: #0a2756;
        }

        .buttons{
            display: flex;
            margin: 15px 5px;
            justify-content: space-between;
            padding: 5px;
            button{
                cursor: pointer;
                font-family: $font-poppins;
                border: none;
                padding: 4px 10px;
                border-radius: 4px;
                background-color: transparent;
                &.reset{
                    font-weight: 600;
                    color: #fff;
                    background-color: #0a2756;
                }
                &.apply{
                    font-weight: 600;
                    margin-left: 25px;
                    color: #0a2756;
                    background-color: #00ffb4;
                }
                &:hover{
                    opacity: 0.7;
                }
            }
        }
    }
}
.chips-filter-container{
    margin-left: 10px;
    .MuiChip-outlinedInfo.chip-custom{
        position: relative;
        border-radius: 5px;
        height: auto;
        background: #272d3d;
        padding: 0 10px;
        margin: 0 8px;
        span.MuiChip-label{
            padding: 5px 15px;
        }
        .MuiSvgIcon-root{
            position: absolute;
            top: -8px;
            right: -6px;
            margin-right: 0;
            background: #272d3d;
            font-size: 16px;
            border:1px solid #00ffb4;
            border-radius: 50%;
        }
    }
    .button-clear{
        background: #272d3d;
        text-transform: capitalize;
        padding: 3px 35px;
        margin: 0 8px;
    }
}