@import '../fonts/fonts';
body {
    background-color: #EDEDED;
    margin: 0;
    padding: 0;
}
.preview__form {
    background-color: rgb(0,93,244);
    background: linear-gradient(180deg, rgba(0,128,255,1) 0%, rgba(32,53,93,1) 61%, rgba(10,39,86,1) 100%);
    padding: 20px 50px;
    //background-attachment: fixed;
    //height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .popupurl {
        width: 100%;
        border-radius: 10px;
        height: 40px;
        border: none;
        padding-left: 12px;
        padding-right: 42px;
        max-width: 244px;
        font-family: $font-poppins;
        margin-top: 20px;
        border: 3px solid #0080ff;
        background-image: url(images/copy.png);
        background-position: 95% center;
        background-repeat: no-repeat;
        cursor: pointer;
    }
    &-bg_waves {
        background-image: url(./images/reticula_x.svg);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.3;
        background-repeat: repeat-y;
        z-index: -1;
        background-position: 45% 0;
        background-size: 125%;
    }
    &-addsize {
        flex-direction: column !important;
        flex-wrap: wrap;
        justify-content: space-between;
        border: 1px solid #0459e3;
        border-radius: 10px;
        padding: 15px 20px;
        >span {
            width: 100%;
            font-size: 17px !important;
            color: #fff;
            position: relative;
            cursor: pointer;
            text-transform: uppercase;
            &::after {
                content: "";
                position: absolute;
                background-image: url('images/addsizeicon.png');
                right: 0;
                top: 5px;
                width: 12px;
                height: 11px;
                transition: all .5s ease-in-out;
            }
            &.open {
                &::after {
                    transform: rotate(-180deg);
                }
            }
        }
        button {
            width: 100%;
            background-color: #0080ff;
            border: none;
            color: white;
            padding: 10px 10px;
            font-size: 17px;
            text-transform: uppercase;
            border-radius: 10px;
            margin-top: 20px;
            font-family: $font-poppins;
            font-weight: 700;
        }
        .optionfullsize {
            width: 100%;
            margin: 15px 0 21px 0;
            color: #fff;
            position: relative;
            .label {
                position: absolute;
                left: 49px;
                width: 137px;
            }
            .switch {
                position: relative;
                display: inline-block;
                width: 44px;
                height: 22px;
              }
              
              .switch input { 
                opacity: 0;
                width: 0;
                height: 0;
              }
              
              .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: transparent;
                -webkit-transition: .4s;
                transition: .4s;
                border: 1px solid white;
              }
              
              .slider:before {
                position: absolute;
                content: "";
                height: 14px;
                width: 14px;
                left: 4px;
                bottom: 3px;
                background-color: white;
                -webkit-transition: .4s;
                transition: .4s;
              }
              
              input:checked + .slider {
                background-color: #0080ff;
              }
              
              input:focus + .slider {
                box-shadow: 0 0 1px #0080ff;
              }
              
              input:checked + .slider:before {
                -webkit-transform: translateX(21px);
                -ms-transform: translateX(21px);
                transform: translateX(21px);
              }
              
              /* Rounded sliders */
              .slider.round {
                border-radius: 34px;
              }
              
              .slider.round:before {
                border-radius: 50%;
              }
        }
        .bannerad{
            position: relative;
            margin: 20px 0 0 0;
            label{
                position: absolute;
                visibility: hidden;
            }
            input{
                width: 100%;
                box-sizing: border-box;
                border-radius: 8px;
            }
        }
        .fieldsad{
           width: 100%;
        }
        .sizead {
            display: flex;
            justify-content: space-between;
            align-items: center;
            label {
                color: #fff;
                margin-bottom: 5px;
                display: block;
            }
            input {
                width: 80px;
                margin: 0 0px;
                text-align: center;
                &::placeholder {
                    text-align: center;
                }
            }
            span {
                font-size: 30px;
                transform: rotate(45deg);
            }
        }
        .container-addsize {
            max-height: 0;
            transition: all .5s ease-in-out;
            overflow: hidden;
            &.open {
                max-height: 350px;
                
            } 
        }
    }
    &-newsize {
        // background-color: transparent;
        // border-radius: 20px;
        // padding: 5px 20px;
        // padding-left: 0;
        // margin-bottom: 0px;
        // position: relative;
        background-color: transparent;
        margin-bottom: 5px;
        padding: 5px 20px 5px 0;
        position: relative;
        &:last-child {
            margin-bottom: 20px;
        }
        .input.style{
            padding: 1px 5px;
            margin: 5px 0 0 0;
            text-decoration: none;
            outline: none;
            background: none;
            color: #fff;
            font-family: $font-poppins;
            font-size: 13px;
            width: auto;
            border: 1px solid #fffa00 !important;
            box-shadow: inset 0px 0px 4px 2px rgb(255 213 4 / 54%);
            border-radius: 5px;
            // border-bottom: 0 !important;
        }
   
        .input.size{
            font-size: 11px;
            min-width: 20px;
            max-width: 21px;
            margin: 0 3px;
        }
      
        .errors {
            background-color: #ef5b2b;
            position:  absolute;
            bottom: -20px;
            left: 39%;
            font-size: 12px;
            color: white;
            padding: 5px 10px;
            padding-left: 25px;
            border-radius: 5px;
            background-image: url(./images/errror.png);
            background-repeat: no-repeat;
            background-size: 13px;
            background-position: 6px 50%;
            font-family: $font-poppins;
            &::before {
                content:"\A";
                border-style: solid;
                border-width: 10px 15px 10px 0;
                border-color: transparent #ef5b2b transparent transparent;
                position: absolute;
                left: calc(50% - 5px);
                top: -13px;
                transform: rotate(90deg);
            }
        }
        >div {
            // font-size: 14px;
            // font-family: $font-poppins;
            // color: #000;
            // font-weight: 600;
            // background-color: #0080ff;
            // font-weight: lighter;
            // color: #fff;
            // display: flex;
            // width: 100px;
            // justify-content: center;
            // margin-left: 10px;
            // padding: 5px 0;
            // border-top-left-radius: 10px;
            // border-top-right-radius: 10px;
            background-color: #0080ff;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            color: #000;
            color: #fff;
            display: flex;
            font-family: Gotham-Book;
            font-size: 11px;
            font-weight: 600;
            font-weight: lighter;
            justify-content: center;
            align-items: center;
            padding: 5px 18px 6px;
            width: fit-content;
        }
        input {
            color: #000;
            border-bottom: 1px solid #000 !important;
            width: calc(100% - 10px);
        }
        .delete-size {
            background-color: #0080ff;
            border: none;
            color: white;
            border-radius: 50%;
            height: 25px;
            width: 25px;
            font-size: 18px;
            padding: 0;
            transform: rotate(45deg);
            position: absolute;
            top: 15px;
            right: -5px;
        }
    }
    &-intro {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
        img {
            max-width: 170px;
        }
        
    }
    h1 {
        font-size: 24px;
        font-family: $font-poppins;
        text-transform: uppercase;
        color: #00ffb4;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 1;
    }
    form {
        margin-top: 30px;
        z-index: 2;
        position: relative;
        .submitbtn {
            cursor: pointer;
            background-color:  #00ffb4;
            border: none;
            color: #0a2756;
            padding: 10px;
            width: 100%;
            font-size: 20px;
            font-weight: light;
            border-radius: 10px;
            text-transform: uppercase;
            font-weight: 700;
            border: 1px solid #00ffb4;
            font-family: $font-poppins;
            margin-top: 40px;
        }
        .field {
            display: flex;
            flex-direction: column-reverse;
            margin-bottom: 2em;
            font-weight: 700;
            font-family: $font-poppins;
            position: relative;
            .errors {
                background-color: #ef5b2b;
                font-weight: 400;
                position:  absolute;
                bottom: -20px;
                left: 23%;
                font-size: 12px;
                color: white;
                padding: 5px 10px;
                padding-left: 25px;
                border-radius: 5px;
                background-image: url(./images/errror.png);
                background-repeat: no-repeat;
                background-size: 13px;
                background-position: 6px 50%;
                z-index: 2;
                &::before {
                    content:"\A";
                    border-style: solid;
                    border-width: 10px 15px 10px 0;
                    border-color: transparent #ef5b2b transparent transparent;
                    position: absolute;
                    left: calc(50% - 5px);
                    top: -13px;
                    transform: rotate(90deg);
                }
            }
            select {
                border: none;
                font-size: 15px;
                padding: 10px 15px;
                font-family: $font-poppins;
                font-weight: 400;
                background-color: white;
                border-radius: 10px;
                appearance: none;
                position: relative;
                background-image: url('images/selecticon.png');
                background-repeat: no-repeat;
                background-position: 93% center;
                option {
                    font-family: $font-poppins;
                    &:first-child {
                        color: #cccccc;
                    }
                }
                &:focus {
                    outline: none;
                }
            }
            .radio-group {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-top: 10px;
                input[type=radio] {
                    position: absolute !important;
                    clip: rect(0, 0, 0, 0);
                    height: 1px;
                    width: 1px;
                    border: 0;
                    overflow: hidden;
                }
                label {
                    background-color: transparent;
                    color: #fff;
                    font-size: 17px;
                    line-height: 1;
                    text-align: center;
                    margin-right: -1px;
                    border: 1px solid #fff;
                    transition: all 0.1s ease-in-out;
                    border-radius: 10px;
                    width: 128px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 20px;
                    cursor: pointer;
                }
                input[type=radio]:checked + label {
                    background-color: #fff;
                    font-weight: 700;
                    box-shadow: none;
                    color: #0080ff;
                }
            }
            
            >label {
                position: relative;
                margin-bottom: 5px;
                color: white;
                font-weight: 700;
                font-family: $font-poppins;
                font-size: 16px;
                text-transform: uppercase;
            }
        }
        label, input[type=text], input[type=number] {
            transition: all 0.2s;
            touch-action: manipulation;
            font-family: $font-poppins;
            font-weight: 400;
            font-size: 16px;
        }
          
        input[type=text], input[type=number] {
            // font-size: 17px;
            // border: 0;
            // font-family: inherit;
            // -webkit-appearance: none;
            // border-radius: 0;
            // padding: 11px 15px;
            // cursor: text;
            // background-color: #fff;
            //font-family: $font-poppins;
            // border-radius: 10px;
            -webkit-appearance: none;
            background-color: #fff;
            border: 0;
            border-radius: 0;
            border-radius: 0 5px 5px;
            cursor: text;
            font-size: 15px;
            padding: 11px 15px;
            &::placeholder {
                font-family: $font-poppins;
                color: #cccccc;
            }
 
        }
        
        
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
          -webkit-box-shadow: 0 0 0px 1000px #00000000 inset;
          transition: background-color 5000s ease-in-out 0s;
        }
        
        input:focus {
            outline: 0;
        }
          
          /**
          * Translate down and scale the label up to cover the placeholder,
          * when following an input (with placeholder-shown support).
          * Also make sure the label is only on one row, at max 2/3rds of the
          * field—to make sure it scales properly and doesn't wrap.
          */
        
    }

    &-logo{
        text-align: center;
        margin-bottom: 60px;
        img{
            width: 255px;
        }
    }

    
    &-footer {
        text-align: center;
        font-family: $font-poppins;
        line-height: 1.3;
        color: #fff;
        font-size: 14px;
        padding: 0 25px;
        margin-top: 50px;
        margin-bottom: 0px;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
            width: 180px;
        }
        span {
            font-family: $font-poppins;
            display: block;
            color: #fff;
            font-size: 10px;
            text-align: center;
            margin-top: 20px;
        }
    }

}



