@import '../../fonts/fonts';

.login {
    display: flex;
    min-height: 100vh;
    &__form {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url('images/bgform.jpg');
        background-color: #282e3e;
        &-signin {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        form {
            text-align: center;
            max-width: 320px;
                    width: 100%;
            h2 {
                text-align: center;
                font-size: 42px;
                color: #ffffff;
                font-family: $font-poppins;
                font-weight: 400;
            }

            .field {
                margin-bottom: 32px;
                position: relative;
                input {
                    max-width: 320px;
                    width: calc(100% - 40px);
                    height: 42px;
                    border-radius: 10px;
                    border: none;
                    padding-left: 40px;
                    &:focus {
                        border: none;
                        outline: none;
                    }
                    &#email {
                        background-image: url('images/user.png') !important;
                        background-repeat: no-repeat;
                        background-position: 13px center;
                        background-size: 20px;
                    }
                    &#password {
                        background-image: url('images/password.png');
                        background-repeat: no-repeat;
                        background-position: 13px center;
                        background-size: 20px;
                    }

                }
                .errors {
                    background-color: #ef5b2b;
                    position:  absolute;
                    bottom: -20px;
                    left: 25%;
                    font-size: 12px;
                    color: white;
                    padding: 5px 10px;
                    padding-left: 25px;
                    border-radius: 5px;
                    background-image: url(./images/errror.png);
                    background-repeat: no-repeat;
                    background-size: 13px;
                    background-position: 6px 50%;
                    font-family: $font-poppins;
                    &::before {
                        content:"\A";
                        border-style: solid;
                        border-width: 10px 15px 10px 0;
                        border-color: transparent #dd4397 transparent transparent;
                        position: absolute;
                        left: calc(50% - 5px);
                        top: -13px;
                        transform: rotate(90deg);
                    }
                }
            }
            .btn {
                width: 160px;
                height: 50px;
                border-radius: 10px;
                background-color: #54edd6;
                color: #272d3d;
                font-size: 23px;
                cursor: pointer;
                border: none;
                &:hover {
                    opacity: 0.7;
                }
            }
            .fields {
                display: flex;
                justify-content: space-between;
                color: #ffffff;
                font-family: $font-poppins;
                margin-bottom: 30px;
                .forgotpassword {
                    a {
                        color: #ffffff;
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
                .checkbox {
                    position: relative;
                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                        margin-right: 20px;
                    }
                    label {
                        margin-left: 25px;
                    }
                    .checkmark {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 20px;
                        width: 20px;
                        background-color: #eee;
                        border-radius: 15px;
                       
                    }

                    &:hover input ~ .checkmark {
                        background-color: #ccc;
                    }
                    input:checked ~ .checkmark {
                        background-color: #ccc;
                    }
                    input:checked ~ .checkmark::after {
                        display: block;
                    }
                    .checkmark {
                        &::after {
                            content: "";
                            position: absolute;
                            display: none;
                            left: 7px;
                            top: 3px;
                            width: 5px;
                            height: 10px;
                            border: solid #272d3d;
                            border-width: 0 2px 2px 0;
                            -webkit-transform: rotate(45deg);
                            -ms-transform: rotate(45deg);
                            transform: rotate(45deg);
                        }
                      }
                }
            }
            input:-webkit-autofill,
            input:-webkit-autofill:hover, 
            input:-webkit-autofill:focus,
            textarea:-webkit-autofill,
            textarea:-webkit-autofill:hover,
            textarea:-webkit-autofill:focus,
            select:-webkit-autofill,
            select:-webkit-autofill:hover,
            select:-webkit-autofill:focus
             {
            -webkit-box-shadow: 0 0 0px 1000px #00000000 inset;
            transition: background-color 5000s ease-in-out 0s;
            }
            input:-internal-autofill-selected, input:-internal-autofill-previewed {
                -webkit-box-shadow: 0 0 0px 1000px #00000000 inset !important;
                 transition: background-color 5000s ease-in-out 0s !important;
                 appearance: none !important;
                 background-color: transparent !important;

            }
        }
    }
    &__intro {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image:url('images/wave.svg'),
        linear-gradient(to bottom, #005df2, #272d3d)
        ;

        &-panel {
            text-align: center;
            h3 {
                font-size: 40px;
                font-family: $font-poppins;
                font-weight: 400;
                color: #54edd6;
            }
        }
    }
}