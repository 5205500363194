@import '../../fonts/fonts';

.Register {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    &__box {
        background-color: #ffffff;
        width: 40%;
        padding: 60px 10px;
        border-radius: 5px;
        box-shadow: 0px 0px 30px 5px #b6b6bf6b;
        &-logo {
            text-align: center;
            p {
                font-family: $font-poppins;
                font-size: 0.8rem;
                color: #90939a;
            }
            .signin {
                font-size: 1.5rem;
                color: #002c6d;
                font-family: $font-poppins;
                margin-bottom: 10px;
            }
            img {
                height: 150px;
                margin-bottom: 10px;
            }

        }
        &-form {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            margin-bottom: 24px;
            input {
                display: block;
                height: calc(1.5em + 0.75rem);
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #495057;
                background-color: #fff;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                position: relative;
                margin-bottom: 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                outline: none;
                border-left: none;
                padding-left: 10px;
                width: 40%;
            }
            &-email {
                display: -ms-flexbox;
                display: flex;
                height: calc(1.5em + 0.75rem);
                padding: 1px 13px;
                -ms-flex-align: center;
                align-items: center;
                margin-bottom: 0;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #495057;
                text-align: center;
                white-space: nowrap;
                background-color: #ffffff;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                img {
                    opacity: .8;
                }
            }
        }
        &-submit {
            width: 30%;
            background-color:#33c1ff;
            border-color:#33c1ff;
            box-shadow: none;
            color: white;
            cursor: pointer;
            font-size: 0.8rem;
            letter-spacing: 0.2rem;
            padding: 1rem 2rem;
            border-radius: inherit;
            border: none;
            text-align: center;
            margin: 0 auto;
            display: block;
        }
        &-footer {
            text-align: center;
            margin-top: 20px;
            img {
                max-width: 100%;
                height: auto;
                width: 60%;
                margin-bottom: 20px;
            }
            p {
                font-family: $font-poppins;
                font-size: 0.8rem;
                color: #90939a;
                a {
                    color:  #33c1ff;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        &-sendEmail {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #90939a;
            margin: 20px 0;
            p {
                font-family: $font-poppins;
            }
            .great {
               color: #b2c236;
               margin-bottom: 10px;
               p {
                font-size: 1.5rem;
               }
            }
            img {
                height: 60px;
            }
        }
    }
}