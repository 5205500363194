@import '../../fonts/fonts';

.ListAllCreativesPage {
    position: relative;
    z-index: 1;
    background-color: #191D28;
    &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        z-index: -1;
        background-image: url("../../components/images/reticula_history.jpeg");
        // background-repeat: repeat-y;
        background-size: 100%;
    }

    &__warning{
        padding: 10px;
        text-align: center;
        font-family: $font-poppins;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        background-color: #ed1b1b;
        color: #fff;
    }

    &__header {
        display: flex;
        padding: 30px 0;
        justify-content: space-between;
        width: 90%;
        margin: 0 auto;
        img{
            width: 180px;
        }
        >div {
            display: flex;
            align-items: center;
            p {
                color: #00ffb4;
                font-weight: 700;
                text-transform: uppercase;
                font-family: $font-poppins;
            }
        }
        .line-separator {
            padding: 0 20px;
            position: relative;
            &::after {
                content: "";
                position: absolute;
                width: 2px;
                height: 30px;
                top: -15px;
                background-color: #ffffff;
            }
        }
        .counter-creatives{
            font-family: $font-poppins;
            display: block;
            text-align: center;
            line-height: 1;
            font-weight: 300;
            .number{
                display: block;
                font-size: 37px;
                color: #0080ff;
            }
            .text{
                color: #fff;
                font-size: 14px;
            }
        }
        &.warning{
            margin-top: 20px;
        }
    }

    &__hightlights {
        width: 90%;
        margin: 0 auto;
        background-color: #3b5278;
        border-radius: 10px;
        .MuiBox-root {
            background-color: #272d3d;
        border-radius: 10px;
        }
        .tabs {
            width: 25%;
            padding: 20px 0;
            .MuiTabs-indicator {
                display: none;
            }
            .MuiTabs-flexContainer {
                align-items: left;
                padding-left: 20px;
            }
            button {
                background-color: #191D28;
                color: #ffffff;
                margin: 10px 0;
                width: 322px;
                text-align: left;
                align-items: flex-start;
                padding: 12px 26px;
                border-radius: 10px;
                transition: all .5s ease-in-out;
                position: relative;
                overflow: visible;
                    p {
                    width: 270px;
                    white-space: nowrap;
                    overflow: hidden;
                    font-family: $font-poppins;
                    text-overflow: ellipsis;
                    font-size: 15px;
                }
                &::after {
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    background-image: url("./images/arrow-white.svg");
                    background-repeat: no-repeat;
                    right: -35px;
                    top: calc(50% - 10px);
                }
                &.Mui-selected {
                    background-color: #00ffb4;
                    color: #0a2756;
                    p{
                        font-weight: 700;
                    }
                    &::after {
                        background-image: url("./images/arrow-blue.svg");
                    }
                }
            }
        }
        .into-tab {
            display: flex;
            height: calc(100%);
            padding: 0;
            .info-preview {
                color: #ffffff;
                font-family: $font-poppins;
                height: calc(100% - 60px);
                width: 25%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-left: 0px;
                padding-top: 30px;
                padding-bottom: 30px;
                align-items: flex-start;
                p {
                    margin: 0;
                }
                .advertiser {
                    display: block !important;
                    color: #52e8d1;
                    font-size: 25px;
                    margin-bottom: 10px;
                    word-break: break-all;
                }
                .date {
                    font-size: 14px;
                    display: block;
                    margin-bottom: 15px;
                }
                .market, .campaign, .date, .format {
                    margin-bottom: 15px;
                }
                .campaign {
                    text-transform: uppercase;
                    font-weight: 700;
                    color: #52e8d1;
                    font-size: 20px;
                }
                button {
                    text-transform: capitalize;
                    background-color: #00ffb4;
                    color: #0a2756;
                    font-weight: 700;
                    padding: 6px 16px;
                    font-family: $font-poppins;
                }
    
            }
            .iframe-creatives {
                overflow: hidden;
                width: 980px;
                position: relative;
              //  height: 300px;
                iframe {
                    // width: 1880px;
                    // height: 1000px;
                    // scale: .6;
                    // border: none;
                    // transform: translate(-630px, -340px);
                    width: 200%;
                    height: 200%;
                    overflow: hidden;
                    border: none;
                    transform: translate(0, 0) scale(0.6);
                    transform-origin: top left;
                }
                .IframeCreatives{
                    .card-iframe.darkmode{
                        background-color: #FFF;
                    }
                }
            }
        }
        .panel {
            width: 75%;
        }
    }
    .listAllCreativesPage__table{
        width: 90%;
        margin: 0 auto;
    }
}

.modal-creative {
    .MuiModal-backdrop {
        backdrop-filter: blur(4px);
    }
    .btn-close {
        position: absolute;
        right: 0;
        top: 0;
        background-color: #005df3;
        border-radius: 0;
        padding: 10px;
        border-bottom-left-radius: 10px;
        min-width: 35px;
        &:hover {
            background-color: #005df3;
            opacity: .7;
        }
    }
    .btn-delete{
        background-color: #ed1b1b;
        &:hover{
            background-color: #bb0000;
        }
    }
    &__warning{
        background-color: #ed1b1b;
        position: absolute;
        left: 0;
        padding: 8px 60px;
        bottom: 150px;
        width: 100%;
        p{
            color: #fff;
            font-family: $font-poppins;
        }
    }
    &__container-iframe{
        overflow: scroll;
        height: 1000px;
        iframe{
            height: 110%;
        }
    }
    &__info {
        position: absolute;
        bottom: 0;
        // background-color: rgba(#3b5278, .8);
        background-color: #272d3d;
        width: 100%;
        font-family: $font-poppins;
        display: flex;
        padding: 20px  60px 10px 60px;
        align-items: center;
        p {
            margin: 0;
            color: #fff;
        }
        .advertiser {
            display: block !important;
            color: #52e8d1;
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 10px;
        }
        .date {
            font-size: 14px;
            display: block;
            margin-bottom: 5px;
            color: #fff;
        }
        .market, .campaign, .format {
            margin-bottom: 15px;
        }
        &-main {
            margin-right: 30px;
        }
    }
}