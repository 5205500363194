@import '../../fonts/fonts';

body {
    transition: all ease-in-out .7s;
}
.TableAllCreatives {
    background-color: transparent!important;
    color: #fff !important;
    table{
        background-color: #0a2756 !important;
    }
    th {
        color: #fff;
        //font-family: $font-poppins;
        border-bottom: 0;
    

    }
    td {
        color: #fff;
        border-bottom: 0;
        font-family: $font-poppins;
     
    }
    tr {
        &:nth-child(even) {
            background-color: #191D28;
        }
        &:nth-child(odd){
            background-color: #272d3d;
        }
    }
    .MuiTableRow-head {
        .MuiTableCell-root {
            cursor: pointer;
            background-color: #0a2756 !important; 
            span{
                position: relative;
            }
        }

        .arrow{
            &::after , &::before{
                content: '';
                position: absolute;
                top: 50%;
                right: -20px;
                width: 7px;
                height: 2px;
                background-color: #FFF;
            }
            &::before{
                transform: rotate(45deg);
                right: -15px;
            }
            &::after{
                transform: rotate(140deg);
            }
            &.arrow-up{
                &::before{
                    transform: rotate(140deg);
                }
                &::after{
                    transform: rotate(45deg);
                }
            }
        }
        
        th {
            font-family: $font-poppins;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 1rem;
        }
        tr {
            td {
                font-family: $font-poppins;
            }
        }
    }
    .btn-livedemo {
        text-transform: capitalize;
        background-color: #00ffb4;
        color: #0a2756;
        font-weight: 700;
        font-family: $font-poppins;
        &:hover{
            color: #fff;
            background-color: #0a2756;
        }
    }
    .pagination-table {
        padding: 40px;
        ul {
            justify-content: center;
            li {
                div {
                    color: #fff;
                }
                button {
                    color: #fff;
                    font-family: $font-poppins;
                    font-size: 1.1rem;
                    &.Mui-selected {
                        color: #00ffb4;
                    }
                }

            }
        }
    }
}


.Filter {
    padding: 15px 5px;
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    &__search {
        display: flex;
        align-items: center;
        ///justify-content: flex-start;
        background-color: #a2a5a9;
        padding: 0px 30px;
        padding-right: 0px;
        border-radius: 10px;
        height: 48px;
        &-textField {
            width: 250px;
            .MuiInputLabel-shrink {
                color: #ffffff;
            } 
            .MuiFilledInput-root {
                background-color: transparent;
                &:hover {
                    background-color: transparent;
                }
                &::before, &::after {
                    display: none;
                }
                //display: ;
            }
            input, label {
                color: #fff;
                &.Mui-focused {
                    color: #ffffff;
                }
            }
        }
        button {
            background-color: #ffffff;
            padding: 12px 25px;
            padding-right: 80px;
            text-transform: capitalize;
            font-family: $font-poppins;
            // display: none;
            img {
                margin-right: 15px;
            }
            &:hover {
                background-color: #ffffff;
            }
        }
    }
}
