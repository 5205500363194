@import '../../fonts/fonts';
.showpreview {
    font-family: $font-poppins;
    display: flex;
    align-items: stretch;
    transition: all ease-in-out .7s;
    background-image: url('../../components/images/reticula_light_mode.jpeg');
    background-size: 100%;
    background-position: center center;
    background-attachment: fixed;
   
    &.darkmode {
        background-color: #191D28;
        background-image: url('../../components/images/reticula_dark_mode.jpeg');
        background-size: 100%;
        background-position: center center;
        background-attachment: fixed;
    }
    &__sidebar {
        position: relative;
        z-index: 1;
        transition: all 0.5s;
        min-height: 100vh;
        max-width: 277px;
        min-width: 277px;
        background-color: rgb(0,93,244);
        background: linear-gradient(180deg, rgba(0,128,255,1) 0%, rgba(32,53,93,1) 61%, rgba(10,39,86,1) 100%);
        padding: 20px 50px;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 10px;
        &::after{
            content: '';
            background-image: url(../../components/images/reticula_x.svg);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.3;
            background-repeat: repeat-y;
            z-index: -1;
            background-position: 45% 0;
            background-size: 125%;
        }
        &-intro {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 15px auto 50px auto;
            img {
                // max-width: 180px;
                width: 255px;
            }

        }
        h1{
            font-size: 24px;
            font-family: $font-poppins;
            color: #00ffb4;
            font-weight: 600;
            margin-bottom: 35px;
            margin-top: 68px;
            text-transform: uppercase;
            line-height: 1;
        }
        &-body {
            div {
                margin-bottom: 40px;
                position: relative;
                font-size: 24px;
                line-height: 1.2;
                font-weight: 200;
                font-family: $font-poppins;
                color: #fff;
                span {
                    display: block;
                    font-size: 16px;
                    color: #fff;
                    text-transform: uppercase;
                    font-family: $font-poppins;
                    font-weight: 700;
                    margin-bottom: 0px;
                }
                &::before {
                    content: "";
                    position: absolute;
                    bottom: -15px;
                    left: 0;
                    width: 54px;
                    height: 1px;
                    background-color: #002c6d;
                }
            }
            .region {
                text-transform: uppercase;
            }

        }
        hr {
            padding: 2px;
            border-radius: 20px;
            background: rgb(0,44,109);
            background: linear-gradient(90deg, rgba(0,44,109,1) 0%, rgba(41,48,152,1) 74%, rgba(104,49,192,1) 100%);
        }
        &-sizes {
            >p {
                display: block;
                font-size: 16px;
                color: #fff;
                font-family: $font-poppins;
                text-transform: uppercase;
                font-weight: 700;
                margin-bottom: 20px;
            }
            .sizes{
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                span {
                    display: block;
                    color: white;
                    margin-bottom: 15px;
                    background-color: transparent;
                    border: 1px solid #fff;
                    border-radius: 10px;
                    max-width: 110px;
                    height: 40px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-left: 40px;
                    padding-right: 15px;
                    margin-right: 10px;
                    font-size: 15px;
                    background-image: url(images/eye.png);
                    background-repeat: no-repeat;
                    background-position: 10px center;
                    background-size: 20px;
                    cursor: pointer;
                    &.hidden-iframe {
                        opacity: .5;
                        background-image: url(images/eye-hidden.png);
                    }
        
                }
            }
        }
        &-footer {
            margin-top: 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            span {
                font-family: $font-poppins;
                display: block;
                font-weight: 600;
                line-height: 1.3;
                color: #fff;
                font-size: 14px;
                text-align: center;
                margin-top: 20px;
                width: 245px;
            }
        }
    }
    &__creatives {
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        width: 100%;
        .darkmode {
            width: 90%;
            margin: 20px 20px;
            margin-bottom: 60px;
            .c-toggle .switch {
                position: relative;
                display: inline-block;
                width: 175px;
                height: 34px;
            }
            
            .c-toggle .switch input {
                opacity: 0;
                width: 0;
                height: 0;
            }
            
            .c-toggle .switch .text-on {
                margin: 7px 14px;
                opacity: 0;
            }
            
            .c-toggle .switch .text-off {
                margin: 7px 14px 7px 38px;
                opacity: 1;
            }
              
            .c-toggle .switch .slider {
                border-radius: 34px;
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba($color: #bfbfbf, $alpha: 0.4);
                -webkit-transition: .4s;
                transition: .4s;
                width: 100%;
            }
            
            .c-toggle .switch .text {
                position: absolute;
                font-size: 0.9rem;
                color: rgba($color: #000, $alpha: 0.4);
            }
              
            .c-toggle .switch .slider:before {
                position: absolute;
                content: "";
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 4px;
                background-color: #ffffff;
                -webkit-transition: .4s;
                transition: .4s;
            }
              
            .c-toggle .switch input:checked + .slider {
                background-color: #0080ff;
            }
              
            .c-toggle .switch input:checked ~ .text-on {
                opacity: 1;
                color: #fff;
            }
            
            .c-toggle .switch input:checked ~ .text-off {
                opacity: 0;
            }
            
            .c-toggle .switch input:focus + .slider {
                box-shadow: 0 0 1px #0080ff;
            }
              
            .c-toggle .switch input:checked + .slider:before {
                -webkit-transform: translateX(140px);
                -ms-transform: translateX(140px);
                transform: translateX(140px);
            }
              
            .c-toggle .switch .slider.round:before {
                border-radius: 50%;
            }
        }
    }
    .my-masonry-grid {
        display: grid;
        width: 100%;
        grid-gap: 60px; /* Espacio entre los elementos */
    }

    @media only screen and (max-width: 775px) {
        flex-direction: column;
        overflow: scroll;
        background-image: none;
        &.darkmode{
            background-image: none;
        }
        &__sidebar {
            min-height: auto;
            max-width: 100%;
            .open-title{
                color: #fff;
                text-align: center;
                margin: 25px 0 0 0;
                font-size: 14px;
                span{
                    position: relative;
                    &::after , &::before{
                        content: '';
                        position: absolute;
                        right: -15px;
                        top: 50%;
                        width: 8px;
                        height: 2px;
                        background-color: #fff;
                    }
                    &::after{
                        right: -20px;
                    }
                    &.open{
                        &::after{
                            transform: rotate(45deg);
                        }
                        &::before{
                            transform: rotate(140deg);
                        }
                    }
                    &.close{
                        &::after{
                            transform: rotate(140deg);
                        }
                        &::before{
                            transform: rotate(45deg);
                        }
                    }
                }
            }
            h1{
                text-align: center;
                margin-top: 35px;
                margin-bottom: 15px;
                font-size: 22px;
            }
            &-body {
                text-align: center;
                div {
                    font-size: 20px;
                    margin-bottom: 5px;
                    font-weight: 600;
                }
            }
        }

        &__creatives{
            overflow-x: scroll;
            padding-bottom: 50px;
        }

        &__footer-mobile{
            text-align: center;
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            font-family: $font-poppins;
            font-size: 12px;
            background-color: #FFF;
            border-top: 1px solid #FEFEFEFE;
            color: #20355d;
            padding: 15px 0;
            font-weight: 600;
        }

       
    }
}