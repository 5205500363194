@import '../../fonts/fonts';

.previewpage__body {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    height: 100%;
    transition: all ease-in-out 0.7s;
    &.darkmode {
        background-color: #191D28;
    }
    &-iframes {
        width: 78%;
        height: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        padding-left: 30px;

        .darkmode {
            width: 100%;
            margin: 20px 20px;
            margin-bottom: 60px;
            .c-toggle .switch {
                position: relative;
                display: inline-block;
                width: 175px;
                height: 34px;
                cursor: pointer;
            }
            
            .c-toggle .switch input {
                opacity: 0;
                width: 0;
                height: 0;
            }
            
            .c-toggle .switch .text-on {
                margin: 7px 14px;
                opacity: 0;
            }
            
            .c-toggle .switch .text-off {
                margin: 7px 14px 10px 38px;
                opacity: 1;
            }
              
            .c-toggle .switch .slider {
                border-radius: 34px;
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba($color: #bfbfbf, $alpha: 0.4);
                -webkit-transition: .4s;
                transition: .4s;
                width: 100%;
            }
            
            .c-toggle .switch .text {
                position: absolute;
                font-size: 0.9rem;
                color: rgba($color: #000, $alpha: 0.4);
                transition: all .5s ease-in-out;
                font-family: $font-poppins;
            }

           

              
            .c-toggle .switch .slider:before {
                position: absolute;
                content: "";
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 4px;
                background-color: #ffffff;
                -webkit-transition: .4s;
                transition: .4s;
            }
              
            .c-toggle .switch input:checked + .slider {
                background-color: #0080ff;
            }
              
            .c-toggle .switch input:checked ~ .text-on {
                opacity: 1;
                color: #fff;
            }
            
            .c-toggle .switch input:checked ~ .text-off {
                opacity: 0;
            }
            
            .c-toggle .switch input:focus + .slider {
                box-shadow: 0 0 1px #0080ff;
            }
              
            .c-toggle .switch input:checked + .slider:before {
                -webkit-transform: translateX(140px);
                -ms-transform: translateX(140px);
                transform: translateX(140px);
            }
              
            .c-toggle .switch .slider.round:before {
                border-radius: 50%;
            }
        }
    }
    .preview__form  {
        position: relative;
        z-index: 2;
        width: 22%;
        max-width: 305px !important;
        min-width: calc(300px - 20px);
    }

}